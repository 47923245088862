// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/lib/utils.ts"
);
import.meta.hot.lastModified = "1734532872614.464";
}
// REMIX HMR END

import { type ClassValue, clsx } from "clsx";
import FormData from "form-data";
import Mailgun from "mailgun.js";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { ZodError, ZodSchema, boolean, z } from "zod";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatNumber(num: number): string {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function formatString(str: string): string {
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function useDebounce<T = any>(value: T, delay: number): [T, boolean] {
  const [debouncedValue, setDebouncedValue] = useState(value);
  let [isDebouncing, setDebouncing] = useState(false);
  useEffect(() => {
    setDebouncing(true);
    const handler = setTimeout(() => {
      setDebouncing(false);

      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return [debouncedValue, isDebouncing];
}

export function formDataToText(formData: any) {
  let text = "";

  for (let [key, value] of formData.entries()) {
    text += `${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}\n`;
  }

  return text;
}

export async function validationAction({
  formData,
  schema,
}: {
  formData: FormData;
  schema: ZodSchema;
}) {
  //@ts-ignore
  const data = Object.fromEntries(Array.from(formData));

  try {
    const result = schema.parse(data);

    return { data: result, errors: null };
  } catch (e) {
    console.error(e);
    const errors = e as ZodError;
    if (!errors.issues) {
      errors.issues = data;
    }

    return {
      data: data,
      errors: errors.issues.reduce(
        (acc, issue) => {
          const key = issue.path[0];

          acc[key] = issue.message;

          return acc;
        },
        {} as { [key: string]: string },
      ),
    };
  }
}

export function validationSchema(value: string, isWithSearch?: boolean) {
  switch (value) {
    case "loans":
      if (isWithSearch) {
        return z.object({
          loan: z
            .string()
            .min(1, { message: "Please enter a valid loan amount" }),
          revenue: z
            .string()
            .min(1, { message: "Please enter a valid revenue amount" }),
          eigencapital: z
            .string()
            .min(1, { message: "Please enter a valid egenkapital amount" }),
          lastResults: z
            .string()
            .min(1, { message: "Please enter a valid lastResults amount" }),
          email: z.string().email({ message: "Please enter a valid email" }),
          phone: z
            .string()
            .min(8, { message: "Please enter a valid phone number" }),
        });
      } else {
        return z.object({
          loan: z
            .string()
            .min(1, { message: "Please enter a valid loan amount" }),
          revenue: z
            .string()
            .min(1, { message: "Please enter a valid revenue amount" }),
          eigencapital: z
            .string()
            .min(1, { message: "Please enter a valid egenkapital amount" }),
          lastResults: z
            .string()
            .min(1, { message: "Please enter a valid lastResults amount" }),
        });
      }

    case "overspending":
      return z.object({
        loan: z
          .string()
          .min(1, { message: "Please enter a valid loan amount" }),
      });
    case "placement":
      return z.object({});
    default:
      throw new Error(`Invalid value: ${value}`);
  }
}

export function convertDataForGTM(actionData: any) {
  const gtmDataLayer = {
    success: actionData.success,
    data: {},
  };

  actionData.data.forEach(([key, value]) => {
    if (key === "text") {
      const lines = value.split("\n");
      const mainData = {};
      lines.forEach((line) => {
        const [dataKey, dataValue] = line.split(": ");
        if (dataKey && dataValue) {
          mainData[dataKey] = dataValue;
        }
      });
      gtmDataLayer.data.mainData = mainData;
    } else {
      gtmDataLayer.data[key] = value;
    }
  });
  return gtmDataLayer;
}

export function formatMoneyInput(e: React.ChangeEvent<HTMLInputElement>) {
  let inputValue = e.target.value;
  inputValue = inputValue.replace(/\D/g, "");
  // Format the value with spaces every three digits
  return inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
